<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              년월
            </div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref="searchSelectVisitor"
                    v-model="now"
                    format="YYYY-MM"
                    depth="Year"
                />
              </li>
            </ul>
            <div class="title">
              수신처
            </div>
            <ul class="content">
              <li class="item date" style="width: 192px">
                <ejs-dropdownlist
                    ref="dropListVisitor"
                    :allowFiltering="false"
                    :dataSource="arr"
                    :fields="visitorCodeOptions"
                    v-model="initialValue"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              v-on:click.native="getReportViewData"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li class="print">
            <erp-button
                button-div="FILE"
                @click.native="onPrintClick"
            >
              인쇄
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="false"/>
    </div>
  </div>
</template>

<script>

import InputDate from '@/components/common/datetime/InputDate';
import { getFormattedTelNumber } from "@/utils/string";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import moment from "moment";
import ReportView from '@/components/common/report/ReportView';
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {commonCodesGetBizMstInfo} from "@/utils/commonCodes";
import ErpButton from "@/components/button/ErpButton.vue";
import {
  DATE_FORMAT_YYYY_MM,
} from '@/utils/date';
import {mapGetters} from "vuex";

export default {
  name: "VisitorStatusStatistics",
  components: {
    InputDate,
    ReportView,
    ErpButton
  },
  async created() {
    //날짜
    this.now = moment().format(DATE_FORMAT_YYYY_MM);
    //공통코드의 데이터
    this.arr = await commonCodesGetCommonCode("REPORT_DEST");
    //발신쪽 데이터 파트
    const  {bizName,telNo,faxNo} = commonCodesGetBizMstInfo();
    this.clipData.sentName=bizName;
    this.clipData.sentTel=getFormattedTelNumber(telNo);
    this.clipData.sentFax=getFormattedTelNumber(faxNo);
    // this.clipData = {
    //   SentName:bizName,
    //   SentTell:telNo,
    //   SentFax:faxNo
    // };
  },
  computed: {
    ...mapGetters(["username"]),
  },
  async mounted() {


    await this.getReportViewData();
  },
  data() {
    return {
      now: null,
      arr: [],
      initialValue: "GOLF",
      visitorCodeOptions: {text: "comName", value: "comCode"},
      data: "",
      clipData: {
        key: null,
        comName:null,
        sentName:null,
        sentTel:null,
        sentFax:null
      },
    };
  },
  methods: {
    async getReportViewData() {
      this.clipData.key = this.now.split("-");
      let res = await GolfErpAPI.fetchVisitorStatusStatistics({
        year: this.clipData.key[0],
        month: this.clipData.key[1]
      });


      //   const result =   this.arr.map(({comCode,comCodeAttrbList,comName}) => {
      //   if(comCode==this.initialValue){
      //     return comCodeAttrbList.map(({attrb, attrbName}) => ({
      //       comName,
      //       attrb,
      //       attrbName,
      //     }));
      //   }
      //
      // });
      // console.log(result);
      const result =   this.arr.find(({comCode}) => comCode == this.initialValue )
          .comCodeAttrbList.map(({attrb,attrbName})=>{
          return {attrb,attrbName};
      });

     this.clipData.comName =  this.arr.find(({comCode}) => comCode == this.initialValue ).comName;


      const reportData = {
        reportJson: {

          data: [
            ...res,
          ],
          year: this.clipData.key[0],
          month: this.clipData.key[1],
          reception: this.initialValue,
          result,
          comName: this.clipData.comName,
          sentName: this.clipData.sentName,
          sentTel: this.clipData.sentTel,
          sentFax: this.clipData.sentFax,
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions: "",
        }
      };

      // console.log(reportData);
      // console.log(JSON.stringify(reportData));

      await this.$refs.reportViewComponent.postReport(
          reportData,
          `${this.$options.name}`,
      );
    },
    onPrintClick() {
      this.$refs.reportViewComponent.printReport();
    }
  }

};
</script>

<!--http://localhost:9041/-->

<!--http://localhost:****/-->

<!--https://api.erp.golf.fixelsoft.com:-->
